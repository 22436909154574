import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";
import ReactDOMServer from 'react-dom/server';

export default function InfoIcon({ icon = "mdi:information-outline", className = "", content }) {
    return (
        <Icon icon={icon} data-tooltip-position-strategy="absolute"
            className={cn("text-muted-foreground relative inline-flex  cursor-pointer text-lg", className)}
            data-tooltip-id="rmp-tooltip"
            data-tooltip-class-name="rmp-tooltip"
            data-tooltip-hidden={!content}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(<p className="md:max-w-[300px] max-w-full">{content}</p>)} />
    )
}

export const InfoIconHtml = ({ icon = "mdi:information-outline", className = "", content }) => {
    return (
        <Icon icon={icon} data-tooltip-position-strategy="absolute"
            className={cn("text-muted-foreground relative inline-flex  cursor-pointer text-lg", className)}
            data-tooltip-id="rmp-tooltip"
            data-tooltip-class-name="rmp-tooltip"
            // data-tooltip-hidden={!content}
            data-tooltip-html={content} />
    )
}
